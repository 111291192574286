import aboutPic from "../assets/images/about_pic.jpg";

const AboutMe = () => {
  return (
    <div
      id="about_me"
      className="flex flex-col  justify-center p-4  shadow-sm bg-gradient-to-r from-gray-50 to-gray-100 border-2  border-x-0 border-gray-300"
      style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <h2 className="text-2xl font-semibold text-blue-500 mb-2 md:p-10">
        About Me
      </h2>
      <div className="p-1  md:p-10 md:pt-0 flex flex-col lg:flex-row items-center gap-2">
        <div className="flex-1 text-gray-800">
          <p className="text-lg mb-4 leading-7 md:leading-8">
            <li className="mb-3 flex gap-1 items-center">
              Hello/Namaste <span className="text-3xl mb-4"> 🙏</span>
            </li>
            <span className="text-blue-500 me-1">I</span>
            <span className=" text-pink-500 me-1">am</span>
            <span className="text-blue-500 me-1 ">Kedar Kandel,</span>a
            self-taught developer and programmer, passionate about continuous
            learning and creating innovative solutions. With hands-on experience
            and dedication, I've honed my skills in web development and software
            engineering, contributing to various projects. I'm also keen on
            exploring different ERP systems, supported by my SAP ERP TERP-10
            certification.
          </p>
          <p className="text-lg mb-4 leading-7 md:leading-8">
            In pursuit of balance, I prioritize regular exercises, including swimming, alongside activities such as reading, hiking, and playing musical instruments. However, my greatest joy stems from exploring new destinations and immersing myself in diverse cultures through travel.
          </p>
        </div>

        <div className="flex-2 p-2  flex justify-center items-center bg-white md:ms-1 shadow-sm">
          <img
            className="object-cover h-full lg:h-[500px] md:p-5 lg:p-16 bg-gray-100"
            src={aboutPic}
            alt="Profile"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
