import { motion } from "framer-motion"


const BackgroundCircle = () => {
  return (
    <motion.div
        initial={{ x: -700, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        className=" absolute  bg-blue-50 h-80 w-80 z-0 rounded-full"
      ></motion.div>
  )
}

export default BackgroundCircle