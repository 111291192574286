import { MenuIcon, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false); 
      }
    };
    window.addEventListener("resize", handleResize); 
    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  return (
    <div
      className=" z-50 fixed top-0 bg-white p-6 px-5 lg:px-20 w-full  flex justify-between text-gray-950 "
      style={{ textShadow: "2px 2px 3px rgba(0, 0, 0, 0.2)" }}
    >
      <ScrollLink
        to={"header_section"}
        spy={true}
        smooth={true}
        offset={-1}
        duration={900}
        className="text-2xl font-bold text-pink-600 cursor-pointer"
        onClick={()=>setIsOpen(false)}
      >
        Ked.dev
      </ScrollLink>
      <ul className="hidden md:flex  gap-6 items-center transition-all ease-in-out">
        <ScrollLink
          to="about_me"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
        >
          About me
        </ScrollLink>
        <ScrollLink
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer "
        >
          Services
        </ScrollLink>
        <ScrollLink
          to="education"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer "
        >
          Edu-&-Experience
        </ScrollLink>
        <ScrollLink
          to="projects"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer "
        >
          Projects
        </ScrollLink>
        <ScrollLink
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer "
        >
          Contact
        </ScrollLink>
        <a
          href="https://mern-blog-app-39y0.onrender.com/"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-gray-500 transition-all"
        >
          Blogs
        </a>
      </ul>
      {isOpen ? (
        <>
          <X
            size={30}
            onClick={() => setIsOpen(false)}
            className=" z-10 text-[#fff] border border-gray-100 shadow-md"
          />
          <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
      ) : (
        <MenuIcon
          size={35}
          className="block md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    </div>
  );
};

export default Navbar;
