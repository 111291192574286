import { FormEvent, useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [notification, setNotification] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    let notificationTimeout: NodeJS.Timeout;

    if (notification) {
      notificationTimeout = setTimeout(() => {
        setNotification(null);
      }, 3000);
    }

    // Cleanup  timeout
    return () => {
      clearTimeout(notificationTimeout);
    };
  }, [notification]);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting && formRef.current) {
      setIsSubmitting(true);
      emailjs
        .sendForm(
          process.env.REACT_APP_YOUR_SERVICE_ID!,
          process.env.REACT_APP_YOUR_TEMPLATE_ID!,
          formRef.current,
          {
            publicKey: process.env.REACT_APP_YOUR_PUBLIC_KEY,
          }
        )
        .then(
          (result) => {
            setNotification("Thank you for your message.");
            formRef.current!.reset();
          },
          (error) => {
            setNotification(
              "Oops! There was an unexpected error while sending a message. Please try again."
            );
          }
        )
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      console.error("Form reference is null");
    }
  };

  return (
    <div
      id="contact"
      className="relative w-full flex flex-col p-4"
      style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <h1 className="text-2xl font-bold md:mb-4 text-blue-500">Contact</h1>
      <div className="w-full mt-5 py-2 md:py-5 flex flex-col justify-center items-center bg-gray-100 text-gray-800 rounded-xl shadow-md border-2 md:border-4 border-gray-300">
        <h2 className="text-2xl font-semibold mb-4">Leave me a message</h2>
        <form
          ref={formRef}
          onSubmit={sendEmail}
          className="space-y-4 p-2 md:p-6 text-lg w-full md:w-9/12 md:border-2 md:shadow-sm"
        >
          <div className="flex flex-col md:flex-row gap-3">
            <div className=" flex-1">
              <label htmlFor="fullName" className="block text-md font-medium ">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="name"
                required
                className="mt-1 px-6 py-4 text-gray-900 outline-none  block w-full shadow-sm  border-gray-300 rounded-md "
                placeholder="Enter your full name"
              />
            </div>
            <div className=" flex-1">
              <label htmlFor="email" className="block text-md font-medium ">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="mt-1 px-6 py-4 text-gray-900 outline-none block w-full shadow-sm  border-gray-300 rounded-md"
                placeholder="Enter your email address"
              />
            </div>
          </div>

          <div>
            <label htmlFor="message" className="block text-md font-medium">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              required
              rows={8}
              className="mt-1 p-2 text-gray-900 outline-none block w-full shadow-sm  border-gray-300 rounded-md"
              placeholder="Enter your message"
            ></textarea>
          </div>
          <div>
            {notification && (
              <span className=" text-sm font-semibold text-gray-900">
                {notification}
              </span>
            )}
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className=" max-w-max py-2 px-4 border border-transparent rounded-md shadow-sm text-blue-500 bg-gray-200 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
