import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";



function App() {
  return (
    <div className="relative container mx-auto scroll-smooth bg-gray-50 flex flex-col justify-center items-center">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<h1 className=" ">Blogs</h1>} />
      </Routes>
    </div>
  );
}

export default App;
