
import { Link as ScrollLink } from "react-scroll";

type Props = {
  buttonClicked: string;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const getInformation = ({ buttonClicked, setIsFormVisible }: Props) => {


  switch (buttonClicked) {
    case "hire":
      return (
        <div id="information" className=" flex flex-col justify-center  bg-gray-600 text-gray-300 p-10 text-lg shadow-lg rounded-lg transition-all ease-in-out">
          <h2 className="text-2xl flex text-white">Looking to hire:</h2>
          <p className=" my-4">
            Thank you for considering me for your team! To initiate the hiring
            process, please send an email to <span className="text-blue-50">kandelkedar07@gmail.com</span>  with the
            following details:
          </p>
          <ul className=" m-6 list-disc list-inside">
            <li>Your full name</li>
            <li>The position you are hiring</li>
            <li>Company name</li>
            <li>Project details</li>
            <li>Offered salary and benefits if available</li>
            <li>Any additional requirements or questions you may have</li>
          </ul>

          <p className="my-4">
            I promise to get back to you as soon as possible.
            I'm excited about the opportunity to potentially work together!
          </p>
          <div className=" flex justify-between items-center">
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={900}
            >
              <button
                onClick={()=>setIsFormVisible(false)}
                className="max-w-max bg-gray-100 hover:bg-white text-gray-600 px-3 py-1 rounded-sm shadow-md"
              >
               Send Email
              </button>
            </ScrollLink>

            <button
              onClick={() => setIsFormVisible(false)}
              className="max-w-max  bg-gray-100 hover:bg-white text-gray-600 px-3 py-1 rounded-sm shadow-md"
            >
              Back
            </button>
          </div>
        </div>
      );
    case "website":
      return (
        <div className=" flex flex-col justify-center  bg-slate-600 text-gray-300 p-10 text-lg shadow-lg rounded-lg">
          <h2 className=" text-2xl flex items-start text-white">
            Need a website:
          </h2>
          <p className="my-4">
            If you need a website for your business or project, you've come to
            the right place! I offer freelance web development services to bring
            your vision to life. Please an email with the following details:
          </p>
          <ul className="m-6 list-disc list-inside">
            <li>Your name or company name</li>
            <li>Details about the website you need</li>
            <li>Any specific features or functionality required</li>
            <li>Preferred timeline for project completion</li>
            <li>Your budget for the project</li>
            <li>Any additional information or questions you may have</li>
          </ul>
          <p className=" my-4">
            Let's work together to create a stunning website that meets your
            needs and exceeds your expectations!
          </p>
          <div className=" flex justify-between">
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              offset={50}
              duration={900}
            >
              <button
                onClick={()=>setIsFormVisible(false)}
                className="max-w-max bg-gray-100 hover:bg-white text-gray-600  px-3 py-1 rounded-sm shadow-md"
              >
                 Send Email
              </button>
            </ScrollLink>

            <button
              onClick={() => setIsFormVisible(false)}
              className="max-w-max bg-gray-100 hover:bg-white text-gray-600 px-3 py-1 rounded-sm shadow-md"
            >
              Back
            </button>
          </div>
        </div>
      );
    default:
      return "";
  }
};