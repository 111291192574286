import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ChevronRight } from "lucide-react";
import Typewriter from "typewriter-effect";

import profilePic from "../assets/images/kedar_pic.png";
import { getInformation } from "../utils";
import ProjectCounter from "./ProjectCounter";
import BackgroundCircle from "./BackgroundCircle";
import backgroundImg from "../assets/images/background.jpeg";

const Header = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("");

  const handleButtonClick = (buttonType: string) => {
    setIsFormVisible(true);
    setButtonClicked(buttonType);
  };

  const information = getInformation({ buttonClicked, setIsFormVisible });

  useEffect(() => {
    if (isFormVisible) {
      window.scrollTo(0, 0);
    }
  }, [isFormVisible]);

  return (
    <motion.div
      id="header_section"
      initial={{ x: 40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -40, opacity: 0 }}
      transition={{ duration: 0.9 }}
      className={`relative min-h-screen  w-full flex  justify-center items-end ease-in-out`}
      style={{
        backgroundImage: `linear-gradient(to top, transparent 90%, rgba(0, 0, 0, 0.3) 99%) , url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="flex flex-col lg:flex-row justify-between items-start lg:items-center pl-10 pb-10 pr-2 lg:pl-5 lg:pb-5 gap-3 md:gap-32"
        style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
      >
        <ProjectCounter />
        <div className="relative flex  md:flex-2 lg:mb-20 items-center justify-center">
          <BackgroundCircle />
          <AnimatePresence>
            <motion.img
              className="md:block w-96 z-10  object-cover rounded-xl"
              alt="profile_pic"
              src={profilePic}
              initial={{ y: 300, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ x: -300, opacity: 0 }}
              transition={{ transitionDelay: 0.5 }}
            />
          </AnimatePresence>
        </div>

        <motion.div
          className="flex-1  flex flex-col justify-center gap-2 md:gap-6 z-10"
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ transitionDelay: "3s" }}
        >
          <h1 className="text-2xl max-w-max md:text-3xl text-gray-900 hover:text-gray-600 transition-all">
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString("Websites done right.").start();
              }}
            />
          </h1>

          <p className="hidden md:block md:w-11/12 text-gray-800 text-lg leading-8">
            I'm a full-stack web developer originally from Nepal, now based in
            Helsinki, Finland. I enjoy building everything from small business
            sites to rich interactive web pages. If you are a business seeking a
            web presence or an employer looking to hire, you can get in touch
            with me
            <span className="border-b border-dotted border-gray-500 ms-1">
              here
            </span>
            .
          </p>
          <p className=" md:hidden mb-2 leading-7">
            I'm a full-stack web developer originally from Nepal, now based in
            Helsinki, Finland. You can get in touch with me
            <span className="border-b border-dotted border-gray-500 ms-1">
              here
            </span>
            .
          </p>
          <motion.div
            initial={{ x: 700, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
            className="flex  flex-col md:flex-row    justify-start gap-3 text-white text-md "
          >
            <button
              onClick={() => handleButtonClick("website")}
              className=" flex rounded-md max-w-max items-center justify-center bg-blue-500 hover:bg-blue-400 py-2 px-3 transition-all"
            >
              Need a website ?
              <ChevronRight />
            </button>

            <button
              onClick={() => handleButtonClick("hire")}
              className=" flex  rounded-md items-center max-w-max justify-center bg-pink-500 hover:bg-pink-400 py-2 px-3 transition-all "
            >
              Looking to hire ?
              <ChevronRight />
            </button>
          </motion.div>
        </motion.div>
      </div>

      {isFormVisible && (
        <div
          onClick={() => setIsFormVisible(!isFormVisible)}
          className=" w-full h-full absolute top-56 md:top-0 z-20 flex justify-center items-center "
        >
          <div onClick={(e) => e.stopPropagation()} className=" md:w-7/12">
            {isFormVisible && information}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Header;
