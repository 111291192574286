import expressEchoImg from "../assets/images/expressEcho.png";
import libraryReduxImg from "../assets/images/libraryRedux.png";
import portfolioImg from "../assets/images/portfolioOld.png";
import bikeDataImg from "../assets/images/bikeDataImg.png";
import calculatorImg from "../assets/images/calculator.png";

import { CodeXml } from "lucide-react";

type Props = {};

const Projects = (props: Props) => {
  return (
    <div
      id="projects"
      className="w-full flex flex-col justify-center text-gray-800   p-2 md:p-4  bg-gradient-to-r from-gray-50 to-gray-100"
      style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <h2 className="font-bold text-2xl mb-4 p-2  text-blue-500 capitalize">
        MY FREE TIME CREATIONS
      </h2>

      <p className="mb-4 p-2 text-lg">
        Beyond my code, delve into my side projects and personal explorations,
        offering insights into the diverse interests and talents of this
        software developer.
      </p>

      <div className=" w-full text-gray-900 shadow-sm leading-7 border rounded-md border-gray-200 p-2 md:p-4 grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="p-3 shadow-md flex flex-col items-center gap-3  rounded-sm transition-all hover:scale-105 hover:shadow-xl">
          <div className="w-full h-full flex justify-center bg-gray-100">
            <img
              src={expressEchoImg}
              alt="express echo walpaper"
              className="w-full object-cover shadow-sm rounded-md"
            />
          </div>

          <div className=" flex flex-col gap-3">
            <h1 className=" text-xl text-pink-500">
              Express Echo (Full-stack)
            </h1>
            <p>
              The project aims to create a platform for creative expression
              through writing. Users can post stories or blogs, selecting their
              genre. All posts are visible on the homepage without login.
              However, features like writing, commenting, liking, editing, and
              deleting require authentication. 
            </p>
            <h1>
              Tools: Typescript, React, Redux-toolkit, TailwindCss, Nodejs,
              Bcrypt, JWT, MongoDB, Docker, Docker Compose , Render, and ...
            </h1>
            <div className=" flex items-center justify-between">
              <a
                href="https://github.com/KedarKandel/Blog"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" flex items-center gap-1 px-3 py-2 text-blue-500 shadow-md border-2 rounded-md ">
                  Code <CodeXml />
                </button>
              </a>

              <a
                href="https://mern-blog-app-39y0.onrender.com/"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" px-3 py-2 text-pink-500 shadow-md border-2 rounded-md ">
                  Demo
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* e-Library project*/}
        <div className="p-3 shadow-md flex flex-col items-center gap-3 rounded-sm transition-all hover:scale-105 hover:shadow-xl">
          <div className="w-full h-full flex justify-center bg-gray-100 ">
            <img
              src={libraryReduxImg}
              alt="e-Library project walpaper"
              className=" w-full object-cover shadow-sm rounded-md"
            />
          </div>

          <div className=" flex flex-col gap-3">
            <h1 className=" text-xl text-pink-500">e-Library (Frontend)</h1>
            <p>
              This project is a frontend-only library management system
              developed to handle tasks such as adding, deleting, editing, and
              borrowing books. It focuses on learning
              client-side state management using Redux and async
              thunks.
              <span className="ms-1 text-gray-800">
                Styling was not the main focus of this project.
              </span>
            </p>
            <h1>
              Tools: React, Redux Toolkit, React Router, Scss for styling,
              Material-UI components, TypeScript for type safety
            </h1>
            <div className=" flex items-center justify-between">
              <a
                href="https://github.com/KedarKandel/Frontend-Library-React-typescript-redux"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" flex items-center gap-1 px-3 py-2 text-blue-500 shadow-md border-2 rounded-md ">
                  Code <CodeXml />
                </button>
              </a>

              <a
                href="https://harmonious-eclair-13e440.netlify.app/"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" px-3 py-2 text-pink-500 shadow-md border-2 rounded-md ">
                  Demo
                </button>
              </a>
            </div>
          </div>
        </div>
        {/*  portfolio project*/}
        <div className="p-3 shadow-md flex flex-col items-center gap-3 rounded-sm transition-all hover:scale-105 hover:shadow-xl">
          <div className="w-full h-full flex justify-center bg-gray-100">
            <img
              src={portfolioImg}
              alt="portfolio project walpaper"
              className="w-full object-cover shadow-sm rounded-md"
            />
          </div>

          <div className=" flex flex-col gap-3">
            <h1 className=" text-xl text-pink-500">Portfolio</h1>
            <p>
              Embracing a learner's mindset, I delved into the realms
              of web development, experimenting with these technologies to
              express my growing passion for coding. Through this website, I
              endeavored to document my journey, celebrating each project as a
              milestone in my evolution from a novice to an aspiring developer.
            </p>
            <h1>
              Tools: React, React Router, Tailwindcss, react-scroll,
              react-icons, emailjs ...
            </h1>
            <div className=" flex items-center justify-between">
              <a
                href="https://github.com/KedarKandel/website-using-tailwindcss"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" flex items-center justify-center gap-1 px-3 py-2 text-blue-500 shadow-md border-2 rounded-md ">
                  Code <CodeXml />
                </button>
              </a>
              <a
                href="https://chic-blancmange-13d184.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <button className=" px-3 py-2 text-pink-500 shadow-md border-2 rounded-md">
                  Demo
                </button>
              </a>
            </div>
          </div>
        </div>
        {/*  journey visualizer project*/}
        <div className="p-3 shadow-md flex flex-col items-center gap-3 rounded-sm transition-all hover:scale-105 hover:shadow-xl">
          <div className="w-full h-full flex justify-center">
            <img
              src={bikeDataImg}
              alt="journey app walpaper"
              className="w-full object-cover shadow-sm rounded-md"
            />
          </div>

          <div className=" flex flex-col gap-3">
            <h1 className=" text-xl text-pink-500">
              Journey Data Visualizer(Full-stack)
            </h1>
            <p>
            The Helsinki Bike Journey Data Visualizer, a Node.js application refining city bike data for sleek visualization. Delve into departure dates, station IDs, distances, and durations, elegantly presented for exploration. Uncover station insights, including departures, returns, average distances, and popular stations.
            </p>
            <h1>
              Tools: Nodejs, ExpressJs, csvtojson, PostgresSQL, React,
              axios, supertest for testing, ...
            </h1>
            <div className=" flex items-center justify-between">
              <a
                href="https://github.com/KedarKandel/journey-bike-app"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" flex items-center justify-center gap-1 px-3 py-2 text-blue-500 shadow-md border-2 rounded-md ">
                  Code <CodeXml />
                </button>
              </a>
              <a
                href="https://journey-app-wa00.onrender.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button className=" px-3 py-2 text-pink-500 shadow-md border-2 rounded-md">
                  Demo
                </button>
              </a>
            </div>
          </div>
        </div>

        {/*  calculator project*/}
        <div className="p-3 shadow-md flex flex-col items-center gap-3 rounded-sm transition-all hover:scale-105 hover:shadow-xl">
          <div className="w-full h-full flex justify-center">
            <img
              src={calculatorImg}
              alt="calculator app walpaper"
              className="w-full object-cover shadow-sm rounded-md"
            />
          </div>

          <div className=" flex flex-col gap-3">
            <h1 className=" text-xl text-pink-500">Calculator</h1>
            <p>
            Experience a minimalist calculator app crafted with React, offering essential arithmetic functions like addition, subtraction, multiplication, and division. With options to clear input, delete digits, and evaluate expressions effortlessly, it's your go-to tool for swift calculations anytime, anywhere.
            </p>
            <h1>
              Tools: React, Inbuilt react-reducers and actions, Cascading
              style sheet
            </h1>
            <div className=" flex items-center justify-between">
              <a
                href="https://github.com/KedarKandel/Calculator-App"
                rel="noreferrer"
                target="_blank"
              >
                <button className=" flex items-center justify-center gap-1 px-3 py-2 text-blue-500 shadow-md border-2 rounded-md ">
                  Code <CodeXml />
                </button>
              </a>
              <a
                href="https://marvelous-cuchufli-12dc9f.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                <button className=" px-3 py-2 text-pink-500 shadow-md border-2 rounded-md">
                  Demo
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
